import { useState } from "react";
import {
	Fade,
	BottomNavigation,
	BottomNavigationAction,
	Menu,
	MenuItem,
	makeStyles,
} from "@material-ui/core";
import {
	HomeRounded,
	InfoRounded,
	StarRounded,
	PermContactCalendarRounded,
	GitHub,
	LinkedIn,
	Twitter,
	PictureAsPdfRounded,
	MailRounded,
} from "@material-ui/icons";
import Main from "./Main";

const useStyles = makeStyles({
	nav: {
		position: "fixed",
		width: "100%",
		bottom: 0,
	},
	menuItem: {
		marginLeft: "10px",
	},
});

function Nav() {
	const classes = useStyles();
	const [nav, setNav] = useState("home");
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleChange = (e, v) => {
		if (v !== "contact") setNav(v);
	};
	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const menuAttr = {
		component: "a",
		target: "_blank",
	};

	const links = {
		GITHUB_LINK: "https://github.com/devblin",
		LINKEDIN_LINK: "https://www.linkedin.com/in/devblin/",
		TWITTER_LINK: "https://twitter.com/devblinx",
		MAIL_LINK: "mailto:dhruwxl@gmail.com",
	};

	return (
		<div>
			<Main page={nav} />
			<div className={classes.nav}>
				<BottomNavigation
					value={nav}
					onChange={handleChange}
					showLabels
				>
					<BottomNavigationAction
						label="Home"
						value="home"
						icon={<HomeRounded fontSize="large" />}
					/>
					<BottomNavigationAction
						label="About"
						value="about"
						icon={<InfoRounded fontSize="large" />}
					/>
					<BottomNavigationAction
						label="Projects"
						value="projects"
						icon={<StarRounded fontSize="large" />}
					/>
					<BottomNavigationAction
						label="Contact"
						value="contact"
						onClick={handleClick}
						icon={<PermContactCalendarRounded fontSize="large" />}
					/>
					<Menu
						id="contact-menu"
						anchorEl={anchorEl}
						keepMounted
						open={open}
						onClose={handleClose}
						TransitionComponent={Fade}
					>
						<MenuItem href={links.GITHUB_LINK} {...menuAttr}>
							<GitHub fontSize="large"></GitHub>
							<span className={classes.menuItem}>GitHub</span>
						</MenuItem>
						<MenuItem {...menuAttr} href={links.LINKEDIN_LINK}>
							<LinkedIn fontSize="large"></LinkedIn>
							<span className={classes.menuItem}>LinkedIn</span>
						</MenuItem>
						<MenuItem {...menuAttr} href={links.TWITTER_LINK}>
							<Twitter fontSize="large"></Twitter>
							<span className={classes.menuItem}>Twitter</span>
						</MenuItem>
						<MenuItem {...menuAttr} href={links.MAIL_LINK}>
							<MailRounded fontSize="large"></MailRounded>
							<span className={classes.menuItem}>Mail</span>
						</MenuItem>
						<MenuItem {...menuAttr}>
							<PictureAsPdfRounded fontSize="large"></PictureAsPdfRounded>
							<span className={classes.menuItem}>Resume</span>
						</MenuItem>
					</Menu>
				</BottomNavigation>
			</div>
		</div>
	);
}

export default Nav;
