const fontFam = {
	title: "MuseoModerno, sans-serif",
	content: "NTR, sans-serif",
};

const rootContainer = {
	display: "flex",
	justifyContent: "center",
	alignContent: "center",
	textAlign: "center",
	width: "95%",
	margin: "4em auto 8em auto",
	height: "100%",
	padding: 0,
};

module.exports = { fontFam, rootContainer };
