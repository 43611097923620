import { Typography, Fade } from "@material-ui/core";
import { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/styles";
import { fontFam } from "../style";
import Typed from "typed.js";
import "../../node_modules/animate.css/animate.min.css";

const useStyles = makeStyles({
	introDiv: {
		height: "80vh",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
		fontFamily: fontFam.title,
		"& > h2": { fontFamily: fontFam.title },
		"& > div": { fontSize: "18px" },
	},
});

function Intro() {
	const classes = useStyles();
	const options = {
		strings: [
			"I am <b>Deepanshu Dhruw</b>.<br>A <b>Software Developer</b>",
		],
		typeSpeed: 50,
		backSpeed: 50,
		startDelay: 1000,
	};
	const el = useRef(null);
	const typed = useRef(null);

	useEffect(() => {
		typed.current = new Typed("#introdesc", options);
		return () => {
			typed.current.destroy();
		};
	}, []);

	return (
		<Fade in={true} timeout={500}>
			<div className={classes.introDiv}>
				<Typography
					variant="h2"
					component="h2"
					className={["animate__tada", "animate__animated"]}
				>
					Hello
				</Typography>
				<div>
					<span id="introdesc" ref={el} />
				</div>
			</div>
		</Fade>
	);
}

export default Intro;
