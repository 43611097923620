import { makeStyles } from "@material-ui/core/styles";
import { Container } from "@material-ui/core";
import { rootContainer } from "../style";
import Intro from "./Intro";
import About from "./About";
import Project from "./Project";

const useStyles = makeStyles({
	root: rootContainer,
});

function Main(props) {
	const classes = useStyles();
	const renderPage = (page) => {
		switch (page) {
			case "home":
				return <Intro />;
			case "about":
				return <About />;
			case "projects":
				return <Project />;
			default:
				break;
		}
	};
	return (
		<Container maxWidth="md" className={[classes.root]}>
			{renderPage(props.page)}
		</Container>
	);
}

export default Main;
