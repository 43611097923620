import {
	Fade,
	Typography,
	CircularProgress,
	Backdrop,
	Snackbar,
	IconButton,
} from "@material-ui/core";
import { ReplayRounded } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { fontFam } from "../style";
import Mycard from "./Mycard";
const useStyles = makeStyles({
	project: {
		display: "flex",
		justifyContent: "center",
		flexWrap: "wrap",
	},
});

function Project() {
	const [projects, setProjects] = useState([]);
	const [net, setNet] = useState(true);
	const [share, setShare] = useState(false);
	const [isLoaded, setLoaded] = useState(true);
	const PINNED_REPO_LINK =
		"https://gh-pinned-repos.egoist.sh/?username=devblin";
	const classes = useStyles();

	const fetchProjects = () => {
		fetch(PINNED_REPO_LINK)
			.then((res) => res.json())
			.then((result) => {
				console.log(result);
				setProjects(result);
				setLoaded(false);
			})
			.catch((e) => {
				setNet(false);
				setLoaded(false);
			});
	};

	const shareLink = (url) => {
		try {
			navigator.share({ url: url });
		} catch (error) {
			setShare(true);
		}
	};

	const handleSnackClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setShare(false);
	};

	useEffect(() => {
		fetchProjects();
	}, []);

	return (
		<Fade in={true} timeout={500}>
			<div>
				<Typography
					variant="h3"
					component="h3"
					style={{ fontFamily: fontFam.title }}
				>
					Projects
				</Typography>
				<br></br>
				<div className={classes.project}>
					<Backdrop
						open={isLoaded}
						style={{ backgroundColor: "transparent" }}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
					{net ? (
						projects.map((value, key) => (
							<Mycard
								title={value.repo}
								timeout={(key + 1) * 200}
								description={value.description}
								link={value.link}
								stars={value.stars}
								key={key}
								shareFunction={shareLink}
							/>
						))
					) : (
						<Backdrop
							open={!isLoaded}
							style={{ backgroundColor: "transparent" }}
						>
							<Alert elevation={6} severity="error">
								Error fetching contents, please check your
								network connection.
								<br></br>
								<IconButton
									onClick={() => {
										setNet(true);
										setLoaded(true);
										fetchProjects();
									}}
								>
									<ReplayRounded fontSize="large"></ReplayRounded>
								</IconButton>
								<br></br>
								Retry
							</Alert>
						</Backdrop>
					)}
					<Snackbar
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={share}
						autoHideDuration={1500}
						onClose={handleSnackClose}
					>
						<Alert elevation={6} severity="error">
							Sharing available for mobile devices only.
						</Alert>
					</Snackbar>
				</div>
			</div>
		</Fade>
	);
}

export default Project;
