import {
	Typography,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	CardActionArea,
	IconButton,
	Tooltip,
	Grow,
	useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
	ShareRounded,
	VisibilityRounded,
	Star,
} from "@material-ui/icons";

const useStyles = makeStyles({
	root: {
		textAlign: "left",
		margin: "5px",
	},
});

function Mycard(props) {
	const classes = useStyles();
	const smMedia = useMediaQuery((theme) => theme.breakpoints.up("sm"));
	const maxWidthM = () => {
		if (!smMedia) return "";
		else return "350px";
	};

	return (
		<Grow in={true} timeout={props.timeout}>
			<Card
				className={classes.root}
				style={{ maxWidth: maxWidthM(), width: "100%" }}
			>
				<CardActionArea>
					<CardHeader
						style={{ padding: "10px 15px 5px 15px" }}
						title={props.title}
					/>
					<CardContent style={{ padding: "5px 15px 5px 15px" }}>
						<Typography variant="body2" color="textSecondary">
							{props.description}
						</Typography>
					</CardContent>
					<CardActions disableSpacing style={{ padding: "5px" }}>
						<Tooltip title="Stars">
							<IconButton key={props.link} aria-label="stars">
								<Star /><span style={{ marginLeft: "2.5px", fontSize: "18px" }}>{props.stars}</span>
							</IconButton>
						</Tooltip>
						<Tooltip title="Share">
							<IconButton
								className="sharing"
								key={props.link}
								aria-label="share"
								onClick={() => {
									props.shareFunction(props.link);
								}}
							>
								<ShareRounded />
							</IconButton>
						</Tooltip>
						<Tooltip title="View">
							<IconButton
								aria-label="view"
								href={props.link}
								target="_blank"
							>
								<VisibilityRounded />
							</IconButton>
						</Tooltip>
					</CardActions>
				</CardActionArea>
			</Card>
		</Grow>
	);
}

export default Mycard;
