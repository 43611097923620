import { useMemo, useState } from "react";
import { CssBaseline, Fab } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BrightnessMediumRounded } from "@material-ui/icons";
import Nav from "./components/Nav";
import WebFont from "webfontloader";

WebFont.load({
	google: {
		families: ["MuseoModerno:200", "NTR"],
	},
});

function App() {
	const [mode, setMode] = useState("dark");
	const [modeTheme, setModeTheme] = useState("#ffffff");
	const theme = useMemo(() =>
		createTheme({
			palette: {
				type: mode,
				primary: {
					main: modeTheme,
				},
			},
		})
	);
	const changeMode = () => {
		if (mode === "dark") {
			setMode("light");
			setModeTheme("#000000");
		} else {
			setMode("dark");
			setModeTheme("#ffffff");
		}
	};

	return (
		<ThemeProvider theme={theme}>
			<CssBaseline />
			<Fab
				size="small"
				onClick={changeMode}
				color="primary"
				aria-label="add"
				style={{ position: "fixed", right: 10, top: 10, zIndex: 2 }}
			>
				<BrightnessMediumRounded />
			</Fab>
			<Nav />
		</ThemeProvider>
	);
}

export default App;
