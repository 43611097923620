import { makeStyles } from "@material-ui/core/styles";
import {
	Typography,
	Fade,
	Grid,
	Divider,
	List,
	ListItem,
	ListItemAvatar,
	Avatar,
	ListItemText,
	useMediaQuery,
	Link
} from "@material-ui/core";
import { fontFam } from "../style";
import {
	SportsEsportsRounded,
	Public,
	DeveloperBoard
} from "@material-ui/icons";

import "../../node_modules/devicon/devicon.min.css";

const useStyles = makeStyles({
	skill: {
		display: "flex",
		justifyContent: "center",
		flexWrap: "wrap",
		fontSize: "45px",
		"& > *": {
			margin: "3px",
		},
	},
	whatido: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	skillTopo: { fontSize: "18px", margin: "5px" },
	divider: { width: "100%", margin: "25px 0px 25px 0px" },
});

function About() {
	const ORG = {
		NOTIFIRE: "https://github.com/notifirehq/notifire",
		GREY_SOFTWARE: "https://github.com/grey-software/focused-browsing",
		HOPPSCOTCH: "https://github.com/hoppscotch/hoppscotch",
		SPIDER: "https://spider.nitt.edu"
	}
	const classes = useStyles();
	const smMedia = useMediaQuery((theme) => theme.breakpoints.up("sm"));
	const ABOUT_ME =
		[`I am a junior at the National Institute of Technology, Tiruchirappalli, pursuing a B.Tech in Instrumentation and Control. 
			I spend most of my time coding and I enjoy building software and experimenting with technologies.
			Currently, member of my college tech-club `, <Link color="secondary" href={ORG.SPIDER}>Spider R&D</Link>, `, where I develop and maintain software projects for campus development.
			I have also contributed to interesting open-source org such as `,
			<Link color="secondary" href={ORG.NOTIFIRE}>@notifire</Link>, ", ",
			<Link color="secondary" href={ORG.GREY_SOFTWARE}>@grey_software</Link>, " & ",
			<Link color="secondary" href={ORG.HOPPSCOTCH}>@hoppscotch</Link>,
		];
	const WHAT_I_DO = [
		{
			primary: "Code",
			secondary: "Building tools, softwares and experimenting with new technologies.",
			avatar: <DeveloperBoard htmlColor={"orange"} />
		}, {
			primary: "Open Source",
			secondary: "Contributing to open-souce projects.",
			avatar: <Public htmlColor={"lightblue"} />
		}, {
			primary: "Gaming",
			secondary: "I play FIFA, Rocket League, Valorant",
			avatar: <SportsEsportsRounded htmlColor={"lightgreen"} />
		}
	]
	const PROGRAMMING_LANGS = [
		"devicon-cplusplus-plain colored",
		"devicon-javascript-plain colored",
		"devicon-php-plain colored",
		"devicon-typescript-plain colored",
		"devicon-python-plain colored",
		"devicon-go-original-wordmark colored",
		"devicon-c-plain colored"
	]
	const FRAMEWORKS_LIBRARIES = [
		"devicon-express-original",
		"devicon-react-original colored",
		"devicon-nextjs-original-wordmark",
		"devicon-nestjs-plain colored",
		"devicon-django-plain",
		"devicon-flask-original",
		"devicon-jest-plain colored",
		"devicon-materialui-plain colored",
		"devicon-bootstrap-plain colored",
		"devicon-jquery-plain colored"
	]
	const TOOLS_TECHNOLOGY = [
		"devicon-nodejs-plain colored",
		"devicon-git-plain colored",
		"devicon-vim-plain colored",
		"devicon-linux-plain",
		"devicon-apache-plain-wordmark",
		"devicon-mongodb-plain colored",
		"devicon-mysql-plain colored",
		"devicon-docker-plain colored",
		"devicon-vscode-plain colored",
		"devicon-heroku-plain colored",
		"devicon-azure-plain colored",
		"devicon-jupyter-plain colored",
		"devicon-html5-plain colored",
		"devicon-css3-plain colored",
		"devicon-sass-original colored",
		"devicon-bash-plain",
		"devicon-npm-original-wordmark colored",
		"devicon-composer-line",
		"devicon-codecov-plain colored",
		"devicon-trello-plain colored",
		"devicon-codepen-plain",
		"devicon-firefox-plain colored"
	]

	return (
		<Fade in={true} timeout={500}>
			<Grid container>
				<Grid item xs={12}>
					<Typography
						variant="h3"
						component="h3"
						style={{ fontFamily: fontFam.title }}
					>
						About
					</Typography>
					<br></br>
					<Typography
						style={{
							fontFamily: fontFam.content,
							fontSize: "20px",
						}}
					>
						{ABOUT_ME}
					</Typography>
				</Grid>
				<Divider className={classes.divider} />
				<Grid item sm={6} xs={12} className={classes.whatido}>
					<Typography
						variant="h4"
						component="h4"
						style={{ fontFamily: fontFam.title }}
					>
						What I Do?
					</Typography>
					<List style={{ maxWidth: "300px" }}>
						{
							WHAT_I_DO.map((value, index) => <ListItem>
								<ListItemAvatar>
									<Avatar>
										{value.avatar}
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={value.primary}
									secondary={value.secondary}
								/>
							</ListItem>)
						}
					</List>
				</Grid>
				{!smMedia ? <Divider className={classes.divider} /> : null}
				<Grid item sm={6} xs={12}>
					<Typography
						variant="h4"
						component="h4"
						style={{ fontFamily: fontFam.title }}
					>
						Skills
					</Typography>
					<br></br>
					<Typography className={classes.skillTopo}>
						Programming Languages:
					</Typography>
					<div className={classes.skill}>
						{
							PROGRAMMING_LANGS.map((value, index) =>
								<i key={index} className={value}></i>
							)
						}
					</div>
					<br></br>
					<Typography className={classes.skillTopo}>
						Frameworks & Libraries:
					</Typography>
					<div className={classes.skill}>
						{
							FRAMEWORKS_LIBRARIES.map((value, index) =>
								<i key={index} className={value}></i>
							)
						}
					</div>
					<br></br>
					<Typography className={classes.skillTopo}>
						Tools & Technologies:
					</Typography>
					<div className={classes.skill}>
						{
							TOOLS_TECHNOLOGY.map((value, index) =>
								<i key={index} className={value}></i>
							)
						}
					</div>
				</Grid>
			</Grid>
		</Fade >
	);
}

export default About;
